<template>
    
  <div v-if="platform=='desktop' || platform=='tablet'" class="container">
    <div class="header">
      <div class="search">
        <span class="p-input-icon-left" style="width:100%">
          <i class="pi pi-search" />
          <InputText
            type="text"
            v-model="search"
            placeholder="חיפוש"
            style="width:100%"
          />
        </span>
      </div>
      <div class="display-options">
        <MultiSelect
          style="width:100%;"
          v-model="selected_display_choosed"
          :options="display_options"
          placeholder="בחירה מה להציג"
        />
      </div>
      <div class="date-from">
        <input type="date" v-model="fromDate" />
      </div>
      <div class="date-to">
        <input type="date" v-model="toDate" />
      </div>
      <div class="clear-dates">
        <Button
          label="איפוס תאריכים"
          class="p-button-secondary"
          @click="handleClearDates"
        />
      </div>
      <div class="export-to-excel">
        <Button
          icon="pi pi-file-excel"
          class="p-button-rounded p-button-success"
          @click="handleExportToCsv"
        />
      </div>
    </div>
    <div class="dashboard">
        <div class="frame total">
            <p>סה"כ בקשות</p>
            <p>{{counter_values.total}}</p>
        </div>
        <div class="frame news">
            <p>סה"כ בקשות חדשות</p>
            <p>{{counter_values.news}}</p>
        </div>
        <div class="frame old">
            <p>סה"כ בקשות שטופלו</p>
            <p>{{counter_values.old}}</p>
        </div>
        <div class="frame waiting">
            <p>סה"כ בקשות ממתינות</p>
            <p>{{counter_values.waiting}}</p>
        </div>
        <div class="frame current">
            <p>מספר בקשות במסך נוכחי</p>
            <p>{{sortedRequests.length}}</p>
        </div>
    </div>
    <div class="display-btns">
      <Button
        style="margin-left:50px"
        label="בקשות ממתינות"
        class="p-button-info"
        @click="handle_records('ממתין')"
      />
      <Button
        style="margin-left:50px"
        label="בקשות חדשות"
        class="p-button-success"
        @click="handle_records('חדש')"
      />
      <Button
        label="בקשות שטופלו"
        class="p-button-danger"
        @click="handle_records('טופל')"
      />
    </div>
    <div class="content">
      <table id="records">
        <tr>
          <th @click="handleSortTable('תאריך הבקשה', !selectedSorted.order)">
            תאריך הבקשה
          </th>
          <th @click="handleSortTable('זמן הבקשה', !selectedSorted.order)">
            זמן הבקשה
          </th>
          <th @click="handleSortTable('סניף', !selectedSorted.order)">סניף</th>
          <th @click="handleSortTable('סוג הבקשה', !selectedSorted.order)">
            סוג הבקשה
          </th>
          <th @click="handleSortTable('פותח הבקשה', !selectedSorted.order)">
            פותח/ת הבקשה
          </th>
          <th @click="handleSortTable('שם העובד', !selectedSorted.order)">
            שם העובד
          </th>
          <th @click="handleSortTable('תז', !selectedSorted.order)">
            ת"ז העובד
          </th>
          <th @click="handleSortTable('מייל', !selectedSorted.order)">
            מייל לעדכון תהליך
          </th>
          <th @click="handleSortTable('בטיפול של', !selectedSorted.order)">
            בטיפול של
          </th>
          <th @click="handleSortTable('סטטוס', !selectedSorted.order)">
            סטטוס
          </th>
          <th>
            עריכה
          </th>
          <th>
            <p style="color:red;">מחיקה</p>
          </th>
          <th>
            <Checkbox v-model="checkeAll" :binary="true" />
          </th>
        </tr>
        <h1  style="text-align:center;" v-if="sortedRequests.length==0 && !isPending">אין רשומות</h1>
        <template v-for="record in sortedRequests" :key="record.id">
          <tr @click="stam(record)">
            <td>
              {{
                new Date(record.createdAt.seconds * 1000).toLocaleDateString(
                  "he"
                )
              }}
            </td>
            <td>
              {{
                new Date(record.createdAt.seconds * 1000).toLocaleTimeString(
                  "he"
                )
              }}
            </td>
            <td>{{ record.branche?record.branche:'לא הוגדר' }}</td>
            <td>{{ record.type }}</td>
            <td>{{ record.ownerName }}</td>
            <td>{{ record.fullName?record.fullName:'לא הוגדר' }}</td>
            <td>{{ record.idNumber }}</td>
            <td>{{ record.email }}</td>
            <td v-if="record.charge">{{ record.charge }}</td>
            <td v-if="record.status == 'חדש'" style="color:green;">
              {{ record.status }}
            </td>
            <td v-if="record.status == 'ממתין'" style="color:#FAB710;">
              {{ record.status }}
            </td>
            <td v-if="record.status == 'טופל'" style="color:red;">
              {{ record.status }}
            </td>
            <td style="text-align:center;">
              <i class="pi pi-pencil" @click="handleRedirect(record)"></i>
            </td>
            <td style="text-align:center;">
                <i class="pi pi-trash" @click="handleDelete(record,record.status)"></i>
            </td>
            <td style="text-align:center;">
              <Checkbox
                name="record"
                :value="record"
                v-model="checkRequests"
              />
            </td>
          </tr>
        </template>
      </table>
    </div>
    <Loader v-if="isPending" />
   <Dialog
        header="חלון מחיקה"
        v-model:visible="showDeleteDialog"
        :style="{ width: '30vw' }"
        position="bottom"
    >
        <p class="p-m-0">האם את/ה בטוח שברצונך למחוק את הרשומות המסומנות?</p>
        <template #footer>
        <Button
            label="לא"
            @click="handleDeleteChecks('לא')"
            class="p-button-success"
        />
        <Button
            label="כן"
            @click="handleDeleteChecks('כן')"
            class="p-button-danger"
        />
        </template>
  </Dialog>
  </div>
  <OnlyForDesktop v-if="platform=='mobile'" />
</template>

<script>
/* eslint-disable */
import InputText from "primevue/inputtext";
import MultiSelect from 'primevue/multiselect';
import Checkbox from 'primevue/checkbox';
import Dialog from 'primevue/dialog';
import {projectFirestore} from '../../../../../firebase/config'
import { ref } from '@vue/reactivity';
import Swal from 'sweetalert2'
import Loader from '../manage_side/loader/Loader.vue'
import {decrement,convert_type_he_to_en} from '../../../../../Methods/office_request_funcs'
import OnlyForDesktop from '../../../../Not_responsible/OnlyForDesktop.vue'
import XLSX from "xlsx";
import {computed, onMounted, onUnmounted, watch } from '@vue/runtime-core';
import router from '../../../../../router';
import store from '../../../../../store';
export default {
    components:{InputText,MultiSelect,Checkbox,Dialog,Loader,OnlyForDesktop},
    props:['docId'],
    setup(props){
        const stam = (record) => {
            console.log(record);
        }
        const platform = ref(computed(()=>{
            return store.state.platform
        }))
        
        const isPending = ref(false)
        const counter_values=ref({
            news:0,
            old:0,
            total:0,
            waiting:2
        })
        const showDeleteDialog=ref(false)
        const search = ref('')
        const display_options = ref([
            'תיקוני שעות',
            'הגדלת שעות',
            'פריטה',
            'אישור מחלה לעובד',
            'תאונת עבודה',
            'טפסים לעובד',
            'כרטיס עובד',
            'העברת עובד',
            'פיטורים',
            'שימוע',
            'התפטרות',
            'קבלת מפרעה',
            'עדכון פרטים אישיים',
            'שינוי פרטי חשבון בנק',
            'שעות עבודה לעובד זמני',
            'הקפה',
            'בקשה כללית',
            'עדכון כתובת אימייל',
            'מחזור בקבוקים',
            'ציוד משרדי'
        ])
        const selected_display_choosed = ref([])
        const records = ref([])
        const fromDate = ref('')
        const toDate = ref('')
        const checkRequests = ref([])
        const checkeAll=ref(false)

        const handleSortTable = (name, order) => {
            selectedSorted.value.name = name;
            selectedSorted.value.order = order;
        };
        const selectedSorted = ref({
            name: "",
            order: false
        });
        
       const sortedRequests = ref(
            computed(() => {
                if (selectedSorted.value.name == "")
                return filterRequestsBySearch.value;
                if (selectedSorted.value.name == "תאריך הבקשה") {
                return filterRequestsBySearch.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.createdAt.toDate() >  b.createdAt.toDate() 
                        ? 1
                        : b.createdAt.toDate()  > a.createdAt.toDate() 
                        ? -1
                        : 0;
                    }
                    return a.createdAt.toDate() > b.createdAt.toDate() 
                    ? -1
                    : b.createdAt.toDate()  > a.createdAt.toDate() 
                    ? 1
                    : 0;
                });
                }
                if (selectedSorted.value.name == "זמן הבקשה") {
                return filterRequestsBySearch.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return new Date(a.createdAt.seconds * 1000).toLocaleTimeString('he') > new Date(b.createdAt.seconds * 1000).toLocaleTimeString('he')
                        ? 1
                        : new Date(b.createdAt.seconds * 1000).toLocaleTimeString('he')> new Date(a.createdAt.seconds * 1000).toLocaleTimeString('he')
                        ? -1
                        : 0;
                    }
                    return new Date(a.createdAt.seconds * 1000).toLocaleTimeString('he') > new Date(b.createdAt.seconds * 1000).toLocaleTimeString('he')
                    ? -1
                    : new Date(b.createdAt.seconds * 1000).toLocaleTimeString('he') > new Date(a.createdAt.seconds * 1000).toLocaleTimeString('he')
                    ? 1
                    : 0;
                });
                }
                if (selectedSorted.value.name == "סניף") {
                return filterRequestsBySearch.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return a.branche > b.branche
                        ? 1
                        : b.branche > a.branche
                        ? -1
                        : 0;
                    }
                    return a.branche > b.branche
                    ? -1
                    : b.branche > a.branche
                    ? 1
                    : 0;
                });
                }
                if (selectedSorted.value.name == "סוג הבקשה") {
                return filterRequestsBySearch.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return a.type > b.type ? 1 : b.type > a.type ? -1 : 0;
                    }
                    return a.type > b.type ? -1 : b.type > a.type ? 1 : 0;
                });
                }
                if (selectedSorted.value.name == "פותח הבקשה") {
                return filterRequestsBySearch.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return a.ownerName > b.ownerName
                        ? 1
                        : b.ownerName > a.ownerName
                        ? -1
                        : 0;
                    }
                    return a.ownerName > b.ownerName
                    ? -1
                    : b.ownerName > a.ownerName
                    ? 1
                    : 0;
                });
                }
                if (selectedSorted.value.name == "שם העובד") {
                return filterRequestsBySearch.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return a.fullName > b.fullName
                        ? 1
                        : b.fullName > a.fullName
                        ? -1
                        : 0;
                    }
                    return a.fullName > b.fullName
                    ? -1
                    : b.fullName > a.fullName
                    ? 1
                    : 0;
                });
                }
                if (selectedSorted.value.name == "תז") {
                return filterRequestsBySearch.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return a.idNumber > b.idNumber
                        ? 1
                        : b.idNumber > a.idNumber
                        ? -1
                        : 0;
                    }
                    return a.idNumber > b.idNumber
                    ? -1
                    : b.idNumber > a.idNumber
                    ? 1
                    : 0;
                });
                }
                if (selectedSorted.value.name == "מייל") {
                return filterRequestsBySearch.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return a.email > b.email ? 1 : b.email > a.email ? -1 : 0;
                    }
                    return a.email > b.email ? -1 : b.email > a.email ? 1 : 0;
                });
                }
                if (selectedSorted.value.name == "סטטוס") {
                return filterRequestsBySearch.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return a.status > b.status ? 1 : b.status > a.status ? -1 : 0;
                    }
                    return a.status > b.status ? -1 : b.status > a.status ? 1 : 0;
                });
                }
                if (selectedSorted.value.name == "בטיפול של") {
                    return filterRequestsBySearch.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return a.charge > b.charge ? 1 : b.charge > a.charge ? -1 : 0;
                    }
                    return a.charge > b.charge ? -1 : b.charge > a.charge ? 1 : 0;
                });
                }
            })
        );
       
       const filterRequestsBySearch = ref(
            computed(() => {
                if (search.value == ""){
                    if(fromDate.value && toDate.value){
                        return records.value.filter(record=>{
                            if(stringTodate(fromDate.value)<=timestampToDate(record.createdAt) &&
                            stringTodate(toDate.value)>=timestampToDate(record.createdAt) ){
                                return record
                            }
                        })
                    }
                    return records.value;
                }
                else{
                    let results=records.value.filter(record => {
                        if (record.createdAt && new Date(record.createdAt.seconds * 1000).toLocaleDateString('he').includes(search.value)) return record;
                        if (record.createdAt && new Date(record.createdAt.seconds * 1000).toLocaleTimeString('he').includes(search.value)) return record;
                        if (record.branche && record.branche.includes(search.value)) return record;
                        if (record.ownerName && record.ownerName.includes(search.value)) return record;
                        if (record.fullName && record.fullName.includes(search.value)) return record;
                        if (record.idNumber && record.idNumber.includes(search.value)) return record;
                        if (record.email && record.email.includes(search.value)) return record;
                        if (record.type && record.type.includes(search.value))return record;
                        if (record.status && record.status.includes(search.value))return record;
                        if (record.status && record.charge.includes(search.value)) return record;
                    });
                    if(fromDate.value && toDate.value){
                        return results.filter(record=>{
                            if(stringTodate(fromDate.value)<=timestampToDate(record.createdAt) &&
                                stringTodate(toDate.value)>=timestampToDate(record.createdAt) ){
                                    return record
                                }
                        })
                    }else{
                        return results
                    }
                }
            
        }));

        const handleClearDates=()=>{
            fromDate.value=''
            toDate.value=''
        }
        const handleRedirect=(record)=>{
            const _record = JSON.stringify(record)
            router.push({name:'manage-request-edit',params:{data:_record,docId:props.docId}})
        }
        const handleDelete = (record,status)=>{
           
            confirm_alert('האם אתה בטוח שברצונך למחוק רשומה זו?')
            .then(async res=>{
                if(res.isConfirmed){
                    await decrement(status,props.docId)
                    await delete_record(record)
                    const index = records.value.findIndex(_record=>_record.id == record.id)
                    records.value.splice(index,1)
                }
            })
        }
        const delete_record=async(record)=>{
            try{
                console.log(props.docId);
                console.log(record.type);
                const request_collection = convert_type_he_to_en(record.type.trim())
                console.log(request_collection);
                await projectFirestore.collection('Applications').doc(props.docId)
                .collection(request_collection).doc(record.id).delete()
            }catch(err){
                console.error('error record:',record);
            }
        }

        const handleDeleteChecks = async(option)=>{
            if(option=='לא'){
                showDeleteDialog.value=false
                checkRequests.value=[]
                checkeAll.value=false
            }
            if(option=='כן'){
                isPending.value = true
                for (const record of checkRequests.value){
                    await decrement(record.status,props.docId)
                    await delete_record(record)
                    const index = records.value.findIndex(_record=>_record.id == record.id)
                    console.log(index);
                    records.value.splice(index,1)
                }
                showDeleteDialog.value=false
                checkRequests.value=[]
                isPending.value=false
                checkeAll.value=false
            }
        }

        watch(selected_display_choosed,()=>{
            localStorage.setItem("M_R_display_choosed",selected_display_choosed.value);
        })
        watch(search,()=>{
            localStorage.setItem("M_R_search",search.value);
        })

        watch(checkeAll,()=>{
            if(checkeAll.value){
                checkRequests.value=[...sortedRequests.value]
            }
            else{
                checkRequests.value=[]
            }
        })

        watch(checkRequests,()=>{
            if(checkRequests.value.length > 0){
                showDeleteDialog.value=true
            }
            else {
                showDeleteDialog.value=false
            }
        })

        const alert =(icon,title,text)=>{
            Swal.fire({
            icon,
            title,
            text,
            })
        }
        const confirm_alert=(title)=>{
           return Swal.fire({
                title,
                icon: 'question',
                iconHtml: '?',
                confirmButtonText: 'כן',
                cancelButtonText: 'לא',
                showCancelButton: true,
                showCloseButton: true
            })
        }

        // for dates
        const stringTodate=(dateString)=>{
            const d=new Date(dateString)
            d.setHours(0,0,0,0)
            return d
        }
        const timestampToDate=(date)=>{
            const d = new Date(date.seconds * 1000)
            d.setHours(0,0,0,0)
            return d
        }

      
        onMounted(async()=>{
            await initial()
            counter_records_RT()
        })
       
       let counter_unsub
       const counter_records_RT=()=>{
           counter_unsub = projectFirestore.collection('Applications').doc(props.docId)
           .onSnapshot(doc=>{
               counter_values.value = doc.data()
           })
       }

        const initial = async()=>{
                let status='חדש'
                const local_storage_data = localStorage.getItem('M_R_display_choosed')
                if(localStorage.getItem('M_R_search')){
                    search.value = localStorage.getItem('M_R_search')
                }
                if(localStorage.getItem("M_R_status")){
                    status=localStorage.getItem("M_R_status")
                }
                if(local_storage_data){
                    selected_display_choosed.value = local_storage_data.split(",")
                    await handle_records(status)
                }
        }

        const handle_records=async(status)=>{
            if(selected_display_choosed.value.length==0){
                alert('error','אופס','עליך לבחור מה להציג')
            }
            else{
                isPending.value = true
                localStorage.setItem("M_R_status",status);
                records.value=[]
                for(const request of selected_display_choosed.value){
                    const request_collection = convert_type_he_to_en(request.trim())
                    const docs = await projectFirestore.collection('Applications').doc(props.docId).collection(request_collection)
                    .where('status','==',status).get()
                    const data = docs.docs.map(doc=>doc.data())
                    records.value.push(...data);
                }
                records.value = records.value.sort((recA,recB) => {
                        if(recA.createdAt.toDate() > recB.createdAt.toDate()){return -1}
                        if(recA.createdAt.toDate() < recB.createdAt.toDate()){return 1}
                })

                isPending.value = false
            }
        }
        
        onUnmounted(()=>{
            if(counter_unsub){
                counter_unsub()
            }
        })

        const handleExportToCsv=()=>{
            let rows = JSON.parse(JSON.stringify(sortedRequests.value)) 
            rows.forEach(row=>{
                row['תאריך הבקשה']=new Date(row.createdAt.seconds * 1000).toLocaleDateString('he')
                row['זמן הבקשה']=new Date(row.createdAt.seconds * 1000).toLocaleTimeString('he')
                row['סניף']=row.branche
                row['סוג הבקשה']=row.type
                row['פותח הבקשה']=row.ownerName
                row['שם העובד']=row.fullName
                row['תז העובד']=row.idNumber
                row['מייל לעדכון תהליך']=row.email
                row['בטיפול של']=row.charge
                row['סטטוס']=row.status
                row['הודעה'] = row.msg
                delete row.id
                delete row.createdAt
                delete row.branche
                delete row.type
                delete row.date
                delete row.ownerName
                delete row.fullName
                delete row.idNumber
                delete row.email
                delete row.charge
                delete row.status
                delete row.amount
                delete row.description
                delete row.uploadedDocuments
                delete row.uploadedDocuments
                delete row.toBranche
                delete row.reason
                delete row.bankName
                delete row.bankAccount
                delete row.brancheBankNumber
                delete row.comments
                delete row.exitTime
                delete row.entranceTime
                delete row.fixDay
                delete row.endDate
                delete row.startDate
                delete row.daysOffMissing
                delete row.role
                delete row.signature
                delete row.fromBranche
                delete row.msg
            })
            let workSheet = XLSX.utils.json_to_sheet(rows);
            let workbook = XLSX.utils.book_new();
            workbook.Workbook = {};
            workbook.Workbook.Views = [];
            workbook.Workbook.Views[0] = {};
            workbook.Workbook.Views[0].RTL = true;

            XLSX.utils.book_append_sheet(workbook, workSheet, "רשימת בקשות משרדיות");
            XLSX.writeFile(workbook, "requests.xlsx");
        }
        return{
            handleClearDates,
            handle_records,
            handleRedirect,
            handleDeleteChecks,
            handleSortTable,
            handleDelete,
            handleExportToCsv,
            search,
            display_options,
            selected_display_choosed,
            fromDate,
            toDate,
            records,
            checkRequests,
            checkeAll,
            filterRequestsBySearch,
            sortedRequests,
            selectedSorted,
            showDeleteDialog,
            counter_values,
            isPending,
            platform,
            stam
        }
    }
}
</script>

<style scoped>
    .container{
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-color: #fff;
    }
    .header{
        padding: 1.5rem;
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        
    }
    .display-btns{
        margin: 5px;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
       
    }
    .dashboard{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: space-between;
        padding: 0 5px;

    }
    .dashboard .frame{
        width: 19%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;
        border-radius: 10px;
    }
    .dashboard .total{
        background-color: teal;
    }
    .dashboard .news{
        background-color: green;
    }
    .dashboard .old{
        background-color: red;
    }
    .dashboard .waiting{
        background-color: indigo;
    }
    .dashboard .current{
        background-color: grey;
    }
    .content{
        position: relative;
        width: 100%;
        height: calc(100% - 160px);
        overflow: hidden;
        overflow-y: auto;
        padding-bottom:10px;
    }
    .content img{
        position: absolute;
        width: 200px;
        height: 200px;
        top:20%;
        left:50%
}
    /* width */
    .content::-webkit-scrollbar {
     width: 10px;
    }

    /* Track */
    .content::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey; 
        border-radius: 10px;
    }
    
    /* Handle */
    .content::-webkit-scrollbar-thumb {
        background: #04AA6D; 
        border-radius: 10px;
    }

    /* Handle on hover */
    .content::-webkit-scrollbar-thumb:hover {
        background:#04AA6D; 
    }
    input[type="date"] {
        height: 35px;
        border: 0.5px solid lightgrey;
        border-radius: 3px;
    }
    input[type="date"]:hover {
        border: 1.1px solid lightblue;
    }
    input[type="date"]:focus {
        outline: none;
        border: 1.1px solid lightblue;
    }

    .display-options{
        width:15% ;
    }
    #records {
        font-family: Arial, Helvetica, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }

    #records td, #records th {
        border: 1px solid #ddd;
        padding: 8px;
    }

    #records tr:nth-child(even){
        background-color: #f2f2f2;
    }

    #records tr:hover {
        background-color: #ddd;
    }

    #records th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: center;
        background-color: #04AA6D;
        color: white;
    }

    i{
        cursor: pointer;
    }
    .pi-pencil:hover{
        color: yellowgreen;
        font-size: 1.2rem;
    }
    .pi-trash:hover{
        color: red;
        font-size: 1.2rem;
    }
    th{
        cursor: pointer;
    }

    
   
</style>